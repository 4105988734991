import { MissingImageIcon } from '@/images/product';
import { ModalControl } from '@/lib/modal';
import {
  ArrowRightIcon,
  BadgeCheckIcon,
  ClipboardListIcon,
  LocationMarkerIcon,
  PhoneIcon,
} from '@heroicons/react/outline';
import clsx from 'clsx';
import { graphql, Link, navigate, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { FC } from 'react';
import { Highlight } from 'react-instantsearch-dom';

import './ProfessionalCard.css';

export function translateAvailableForItem(availableForItem: string): string {
  switch (availableForItem) {
    case 'PROJECTS':
      return 'proiecte & evenimente';
    case 'EMPLOYMENT':
      return 'angajare';
    default:
      return availableForItem;
  }
}

export function translateAvailableFor(professional: any): string | undefined {
  if (!professional?.availableFor?.length) return undefined;
  return professional.availableFor.map(translateAvailableForItem).join(', ');
}

type ProfessionalListCardProps = {
  professional: any;
  trackClick?: () => void;
  contactModal: ModalControl;
  requestQuoteModal: ModalControl;
  isSearchResult: boolean;
};

const ProfessionalCard: FC<ProfessionalListCardProps> = ({
  professional,
  trackClick,
  contactModal,
  requestQuoteModal,
  isSearchResult,
}) => {
  // eslint-disable-next-line no-underscore-dangle
  const { bgImage } = useStaticQuery(graphql`
    query {
      bgImage: file(relativePath: { eq: "fso/kitchen-background.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
    }
  `);

  return (
    <Link
      to={professional.path}
      className={clsx('ProfessionalCard w-full grid grid-cols-1 bg-white')}
      onClick={(event) => {
        event.preventDefault();
        try {
          trackClick?.();
        } catch {
          // n ai csf
        }
        return navigate(professional.path);
      }}
    >
      <div className="lg:row-span-3 bg-yellow h-52 -mx-4 lg:mx-0 overflow-hidden">
        <GatsbyImage
          className="w-full h-full"
          alt={`${professional.name} showcase project`}
          image={getImage(
            professional.coverImage
              ? ({
                  childImageSharp: { gatsbyImageData: professional.coverImage },
                } as any)
              : bgImage
          )}
          objectFit="cover"
          objectPosition="center"
        />
      </div>

      <div
        className="lg:col-start-2 mt-4 mb-2 lg:mx-4 flex gap-4"
        style={{
          maxWidth: `calc(100vw - 2rem)`,
        }}
      >
        <div className="w-14 h-14 rounded-full overflow-hidden">
          {professional.image ? (
            <GatsbyImage
              className="w-full h-full"
              alt={professional.name}
              image={getImage({
                childImageSharp: { gatsbyImageData: professional.image },
              } as any)}
              objectFit="cover"
              objectPosition="center"
            />
          ) : (
            <MissingImageIcon className="w-full h-full" />
          )}
        </div>
        <div className="flex-1 flex flex-col min-w-0">
          <div className="flex items-center text-primary-300 font-semibold truncate">
            {professional.hasBadge && (
              <BadgeCheckIcon className="h-4 w-4 mr-1" />
            )}
            <span>
              {isSearchResult ? (
                <Highlight attribute="name" hit={professional} tagName="mark" />
              ) : (
                professional.name
              )}
            </span>
          </div>
          <div className="text-xs opacity-50 truncate">
            {isSearchResult ? (
              <Highlight
                attribute="companyName"
                hit={professional}
                tagName="mark"
              />
            ) : (
              professional.companyName
            )}
          </div>
          {professional.category && (
            <div className="text-gray-500 truncate">
              {professional.category}
            </div>
          )}
        </div>
      </div>
      <div className="lg:col-start-2 lg:mx-4 mb-3">
        Disponibil pentru:{' '}
        <span className="font-bold">{translateAvailableFor(professional)}</span>
      </div>
      <div className="lg:col-start-2 lg:mx-4 opacity-60 truncate-3">
        {isSearchResult ? (
          <Highlight
            attribute="description"
            hit={professional}
            tagName="mark"
          />
        ) : (
          professional.description
        )}
      </div>
      <div className="lg:col-start-2 lg:mx-4 flex justify-end items-center text-primary-300 mt-2">
        Mai mult <ArrowRightIcon className="h-4 w-4 ml-2" />
      </div>

      <div className="lg:col-start-3 lg:row-start-1 flex items-center my-2 lg:px-4">
        <LocationMarkerIcon className="mr-2 w-4 h-4" />
        <span className="text-sm opacity-60">
          {isSearchResult && professional.address ? (
            <Highlight attribute="address" hit={professional} tagName="mark" />
          ) : (
            professional.address ?? 'Romania'
          )}
        </span>
      </div>

      <div className="lg:col-start-3 lg:row-start-2 lg:row-span-2 -mx-4 lg:mx-0 py-3 px-4 bg-white grid grid-cols-2 lg:grid-cols-1 gap-4 items-center">
        <button
          id="requestProfessionalContactInfo"
          type="button"
          className="flex items-center justify-center px-3 lg:px-8 py-2 border border-transparent rounded shadow-sm ring ring-yellow"
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            trackClick?.();
            contactModal.open({ professional });
          }}
        >
          <PhoneIcon className="w-5 h-5 mr-2 lg:mr-4" />
          Contact
        </button>

        <button
          type="button"
          className="flex items-center justify-center px-3 lg:px-8 py-2 border border-transparent rounded shadow-sm font-semibold bg-yellow hover:bg-yellow"
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            trackClick?.();
            requestQuoteModal.open({ professional });
          }}
        >
          <ClipboardListIcon className="w-5 h-5 mr-2 lg:mr-4" />
          Cere oferta
        </button>
      </div>
    </Link>
  );
};

export default ProfessionalCard;
