import Modal from '@/components/Modal';
import CheckboxInput from '@/components/form/CheckboxInput';
import TextInput from '@/components/form/TextInput';
import { SAVED_DETAILS_KEY } from '@/lib';
import { ModalControl, useModal } from '@/lib/modal';
import { algoliaAnalytics } from '@/lib/search';
import { getUserID } from '@/lib/tracking';
import { ClipboardListIcon } from '@heroicons/react/outline';
import { RefreshIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { Link } from 'gatsby';
import React, { FC, useEffect, useRef, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import * as Yup from 'yup';

import { Professional } from '../../../graphql-types';

export type RequestQuoteModalData = { professional: Professional };

type RequestQuoteModalProps = {
  control: ModalControl<RequestQuoteModalData>;
};

const RequestQuoteModal: FC<RequestQuoteModalProps> = ({ control }) => {
  const [queryID] = useQueryParam('qid', StringParam);
  const [, setError] = useState<string>();
  const successModal = useModal();

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phoneNumber: '',
      description: '',
      toc: false,
      rememberMe: false,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please provide your name'),
      email: Yup.string()
        .email('Invalid email address')
        .required('Please provide your email address'),
      phoneNumber: Yup.string().required('Please provide your phone number'),
      description: Yup.string().required(
        'Please provide a project description'
      ),
      toc: Yup.boolean()
        .isTrue('You must accept T&C')
        .required('You must accept T&C'),
    }),

    onSubmit: async (values, { setSubmitting }) => {
      setError(undefined);
      setSubmitting(true);

      try {
        if (values.rememberMe) {
          localStorage.setItem(
            SAVED_DETAILS_KEY,
            JSON.stringify({
              email: values.email,
              name: values.name,
              phoneNumber: values.phoneNumber,
            })
          );
        } else {
          localStorage.removeItem(SAVED_DETAILS_KEY);
        }
      } catch {
        // ata ete, n ai csf
      }

      try {
        const formData = new URLSearchParams();

        formData.append('email', values.email.replace('@', `+${Date.now()}@`));
        formData.append('name', values.name);
        formData.append('custom_phone_number', values.phoneNumber);
        formData.append(
          'custom_requested_quote',
          `[${control.data.professional.name} - ${control.data.professional.companyName}]: ${values.description}`
        );

        formData.append('start_day', '0'); // set autoresponder to 0
        formData.append('campaign_token', 'reT9k'); // OptiOffer Quote Requests
        formData.append('webform[consent#honK-ver#zqHo]', `${values.toc}`); // T&C consent field

        await fetch('https://app.getresponse.com/add_subscriber.html', {
          mode: 'no-cors',
          method: 'POST',
          body: formData,
        });

        successModal.open();
      } catch (ex) {
        setError(ex?.toString());
      }

      try {
        algoliaAnalytics('convertedObjectIDsAfterSearch', {
          index: process.env.GATSBY_ALGOLIA_PROFESSIONALS_INDEX_NAME,
          eventName: 'Quote Requested',
          queryID,
          objectIDs: [control.data.professional.path],
          userToken: getUserID(),
        });
      } catch {
        // ata ete, n ai csf
      }

      setSubmitting(false);
      control.close();
    },
  });
  const formikRef = useRef(formik);

  useEffect(() => {
    const serializedData = localStorage.getItem(SAVED_DETAILS_KEY);
    if (!serializedData) return;
    const savedData = JSON.parse(serializedData);
    formikRef.current.resetForm({
      values: {
        ...savedData,
        description: '',
        toc: true,
        rememberMe: true,
      },
    });
  }, [formikRef]);

  return (
    <>
      <Modal
        control={control}
        title={`Cere oferta de pret de la ${control.data?.professional.name}`}
        formProps={{ onSubmit: formik.handleSubmit }}
        renderActions={(props) => (
          <>
            <button
              {...props}
              id="requestQuoteFromProfessional"
              className={clsx(props.className, 'bg-yellow font-semibold')}
              type="submit"
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting ? (
                <RefreshIcon className="animate animate-spin h-6" />
              ) : (
                <>
                  <ClipboardListIcon className="w-5 h-5 mr-2 lg:mr-4" />
                  Cere oferta acum
                </>
              )}
            </button>
          </>
        )}
      >
        <div className="flex-1 flex flex-col gap-3 mb-4">
          <div className="font-semibold text-base text-brown">
            Descrie pe scurt proiectul pentru care doresti oferta. Daca
            profesionistul are nevoie de detalii suplimentare, te va contacta
            telefonic in maxim 24h.
          </div>
          <TextInput
            formik={formik}
            name="description"
            type="textarea"
            inputProps={{ rows: 5 }}
            label="Descriere proiect"
            placeholder="Scrie o scurta descrie a proiectului"
            className=""
          />

          <div className="font-semibold text-base text-brown mt-6">
            Unde doresti sa te contactam despre oferta de pret?
          </div>

          <TextInput
            formik={formik}
            name="name"
            label="Nume"
            placeholder="ex: Melon Musk"
            className=""
          />
          <TextInput
            formik={formik}
            name="email"
            label="Email"
            placeholder="ex: melon@spaceY.com"
            className=""
          />
          <TextInput
            formik={formik}
            name="phoneNumber"
            label="Numar de telefon"
            placeholder="ex: 07xx xxx xxx"
            className=""
          />
          <CheckboxInput formik={formik} name="toc" className="">
            Accept
            <Link
              to="/terms-and-conditions/"
              target="_blank"
              className="ml-1 underline"
            >
              Termenii si Conditiile
            </Link>
          </CheckboxInput>
          <CheckboxInput formik={formik} name="rememberMe">
            Tine minte datele pentru urmatoarea oferta
          </CheckboxInput>
        </div>
      </Modal>

      <Modal
        control={successModal}
        title="Multumim! Cerea a fost inregistrata."
        renderActions={(props) => (
          <button
            {...props}
            className={clsx(
              props.className,
              'bg-primary hover:bg-primary-200 text-white'
            )}
            type="button"
            onClick={() => {
              successModal.close();
              control.close();
            }}
          >
            OK
          </button>
        )}
      >
        <p
          id="requestQuoteFromProfessionalSuccessMessage"
          className="font-semibold mb-4 text-base"
        >
          Furnizorul va reveni cu o oferta in maxim 24h.
        </p>
        <p className="mb-8 ">
          Suntem aici pentru tine. Daca ai intrebari sau nelamuriri nu ezita sa
          ne contactezi la{' '}
          <a
            href="mailto:support@optioffer.com"
            className="text-primary underline"
          >
            support@optioffer.com
          </a>{' '}
          sau{' '}
          <a href="tel:+40747761756" className="text-primary underline">
            0747 761 756
          </a>
          .
        </p>
      </Modal>
    </>
  );
};

export default RequestQuoteModal;
